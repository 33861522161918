import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
const appId = 'wx6dd979cedea62347'
// const appId = 'wxcc3ef5e83f22d8f1'
router.beforeEach((to, from, next) => {
  // next();
  if (!to.path.includes('scope')) {
   
    let _token = sessionStorage.getItem('_token');
    if (!_token) {
      sessionStorage.setItem('beforeUrl', to.fullPath);
      let local = encodeURIComponent('https://zmnh.bowu66.cn/api/wx/login')
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      next();
    } else {
      next();
    }
  } else {
    next();
  }

})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
